import React from "react";
import {useTranslation} from "react-i18next";
import "../../translations/i18n";
import {getOurTechnnologies} from "../../utils/Common";

export default function NosTechnologies() {
    const {t} = useTranslation();
    const technologies = getOurTechnnologies();
    return (
        <div className="-mt-24">
            {technologies.map((technology, index) => (
                <>
                    {index % 2
                        ?
                        <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                            <div className="space-y-0">
                                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                    <div
                                        className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                                        <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                            <div aria-hidden="true">
                                                <img
                                                    src={"technologies/" + technology.image}
                                                    alt={t(technology.title)}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                                        <div key=""
                                             className="flex flex-col-reverse lg:grid lg:items-center">
                                            {/*  <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                        </div>*/}
                                            <div
                                                className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                                <h2 className="text-lg font-bold text-gray-900 mb-4">{t(technology.title)}</h2>
                                                {technology.paragraphes.length > 0 ?
                                                    <>
                                                        {technology.paragraphes.map((paragraphe, index) => (
                                                            <p className="mt-2 text-sm md:text-base text-gray-500">{t(paragraphe)}</p>
                                                        ))}
                                                    </>
                                                    : ''}

                                                {technology.lists.length > 0 ?
                                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                                        {technology.lists.map((list, index) => (
                                                            <li className="mt-1 list-item">{t(list)}</li>
                                                        ))}
                                                    </ul>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-4">
                            <div className="space-y-0">
                                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                    <div
                                        className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                                        <div key=""
                                             className="flex flex-col-reverse lg:grid lg:items-center">
                                            {/*<div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            */}
                                            <div
                                                className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                                <h2 className="text-lg font-bold text-gray-900 mb-4">{t(technology.title)}</h2>
                                                {technology.paragraphes.length > 0 ?
                                                    <>
                                                        {technology.paragraphes.map((paragraphe, index) => (
                                                            <p className="mt-2 text-sm md:text-base text-gray-500">{t(paragraphe)}</p>
                                                        ))}
                                                    </>
                                                    : ''}

                                                {technology.lists.length > 0 ?
                                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                                        {technology.lists.map((list, index) => (
                                                            <li className="mt-1 list-item">{t(list)}</li>
                                                        ))}
                                                    </ul>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                                        <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                            <div aria-hidden="true">
                                                <img
                                                    src={"technologies/" + technology.image}
                                                    alt={t(technology.title)}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </>
            ))}

        </div>
    )
}
